import { UniversalLinkProps } from "~/components/UniversalLink.vue";

interface Options {
  baseUrl?: string;
}

export const useUniversalLink = (options?: Options) => {
  const config = useRuntimeConfig();
  const { locale } = useI18n();

  const universalLink = ({ baseUrl, cz, com, to }: UniversalLinkProps) => {
    baseUrl = baseUrl ?? options?.baseUrl;

    return (
      to ??
      {
        cs: `${baseUrl ?? config.public.apiDomainCz}${cz}`,
        en: `${baseUrl ?? config.public.apiDomainCom}${com}`,
      }[locale.value]
    );
  };

  return universalLink;
};
